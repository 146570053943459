<template>
  <div id="print-div" class="flex flex-col h-full py-1 px-6 gap-10 bg-white">
    <header id="header" class="flex flex-row justify-between p-6">
      <div class="flex flex-col font-bold text-black gap-5 w-1/2 pr-20">
        <p>
          PT. SINARMAS DISTRIBUSI NUSANTARA <br />Cab. Jakarta Selatan<br />
          Pergudangan Malatex Jl. Raya Bogor KM.30 Kel. Tugu, Kec. Cimanggis
          Depok
        </p>
        <p>021-87727150</p>
        <p>NPWP SDN: 01.604.506.4-007.000</p>
        <p>
          Payer : <br />
          ANEKA BUANA FATMAWATI MM PT. HASIL KARSA MAKMUR JL. RS. FATMAWATI
          NO.15, RT.002/01 PONDOK LABU CILANDAK
        </p>
        <p>NPWP Customer : 01.372.280.6-062.000</p>
      </div>
      <div class="flex flex-col w-1/2 pl-20">
        <h4 class="text-center font-bold text-black">Faktur</h4>
        <h4 class="text-center font-bold text-black">(Billing)</h4>
        <hr class="border-dashed border-0 border-t border-black mt-2 mb-2" />
        <div
          class="grid-display"
          v-for="(data, indextr) in headerData"
          :key="indextr"
        >
          <div class="text-left">{{ data.title }}</div>
          <div class="text-center">{{ data.separator }}</div>
          <div class="text-left">{{ data.value }}</div>
        </div>
      </div>
    </header>

    <div id="line" class="flex flex-col px-6 py-10">
      <table>
        <thead>
          <!-- Product Quantity UoM Price Value Gross Value -->
          <tr
            class="border-dashed border-0 border-t border-b border-black text-left font-bold text-black"
          >
            <td class="w-2/5 py-2">Product</td>
            <td class="w-1/5 py-2 text-center">Quantity</td>
            <td class="py-2">UoM</td>
            <td class="py-2">Price Value</td>
            <td class="py-2">Gross Value</td>
          </tr>
        </thead>
        <tbody>
          <tr
            :class="
              i === bodyData.length - 1
                ? 'border-dashed border-0 border-b border-black'
                : ''
            "
            v-for="(data, i) in bodyData"
            :key="generateKey(i)"
          >
            <td :class="determineLineClass(i)">{{ data.name }}</td>
            <td :class="`${determineLineClass(i)} text-right pr-6`">
              {{ data.quantity }}
            </td>
            <td :class="determineLineClass(i)">{{ data.uom }}</td>
            <td :class="determineLineClass(i)">
              {{ `${data.priceValue} / 1 ${data.uom}` }}
            </td>
            <td :class="`${determineLineClass(i)} text-right`">
              {{ priceFormat(data.grossValue) }}
            </td>
          </tr>
          <tr
            :class="
              i === totalData.length - 1
                ? 'border-dashed border-0 border-b border-black'
                : ''
            "
            v-for="(data, i) in totalData"
            :key="i"
          >
            <td></td>
            <td></td>
            <td :class="`font-bold text-black ${determineTotalClass(i)}`">
              {{ data.title }}
            </td>
            <td></td>
            <td
              :class="`font-bold text-black text-right ${determineTotalClass(
                i
              )}`"
            >
              {{ data.value }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex font-bold text-black gap-1 mt-2">
        <span>{{ "SAY" }}</span>
        <span>:</span>
        <span id="dummy2">{{
          "EMPAT JUTA TUJUH RATUS LIMA PULUH TIGA RIBU EMPAT RATUS ENAM PULUH RUPIAH"
        }}</span>
      </div>
      <hr class="border-dashed border-0 border-t border-black mt-2 mb-2" />
      <span class="font-bold text-black">Sales Order : {{ "7840335562" }}</span>
      <span class="font-bold text-black"
        >Customer PO. No. : {{ "ZS240320-002011/20032024" }}</span
      >
      <span class="font-bold text-black">Message : {{ "" }}</span>
      <hr class="border-dashed border-0 border-t border-black mt-2 mb-2" />
      <div class="flex flex-col gap-6 mt-6">
        <div class="flex flex-col">
          <span class="font-bold text-black">Notes:</span>
          <span id="dummy">
            This is a computer generated document and no signature is
            required</span
          >
        </div>
        <span class="font-bold text-black">Bank Account : </span>
      </div>
    </div>

    <vs-popup
      fullscreen
      title="fullscreen"
      :active.sync="showPdf"
      :button-close-hidden="false"
    >
      <div class="flex flex-col gap-6 h-full">
        <iframe id="pdf-preview" class="w-full" style="min-height: 80vh" />
        <div class="flex gap-3 w-full justify-end">
          <vs-button color="danger" @click="handleClosePopUp">Cancel</vs-button>
          <!-- <vs-Button @click="handlePrintPDFPreview">Print</vs-Button> -->
        </div>
      </div>
    </vs-popup>

    <!-- <tfoot class="print-footer">
        <tr>
          <td>
            <div class="flex flex-col gap-6 mt-6">
              <div class="flex flex-col">
                <span class="font-bold text-black">Notes:</span>
                <span>
                  This is a computer generated document and no signature is
                  required</span
                >
              </div>
              <span class="font-bold text-black">Bank Account : </span>
            </div>
          </td>
        </tr>
      </tfoot> -->
  </div>
</template>
<script>
import { jsPDF } from "jspdf";
import moment from "moment";
import autoTable from "jspdf-autotable";
import angkaTerbilang from "@develoka/angka-terbilang-js";
export default {
  async mounted() {
    try {
      this.$vs.loading();
      const id = this.$route.params.id;
      const resp = await this.getInvoiceData(parseInt(id));
      if (resp.code > 299) {
        throw new Error(resp.message);
      }
      const invoiceData = resp.data.invoice;
      const invoiceLine = resp.data.invoiceLine[0].Line;
      const salesOrder = resp.data.salesOrder[0];
      const customer = resp.data.customer;
      this.headerData = [
        { title: "Billing No.", separator: ":", value: invoiceData.Code },
        {
          title: "Billing Date",
          separator: ":",
          value: this.dateFormat(invoiceData.Date),
        },
        { title: "Order No.", separator: ":", value: salesOrder.Code },
        { title: "Order Date", separator: ":", value: salesOrder.Date },
        { title: "Customer No.", separator: ":", value: customer.code },
        {
          title: "Sales Office",
          separator: ":",
          value: "",
        },
        {
          title: "Condition Delivery",
          separator: ":",
          value: salesOrder.DeliveryTypeName
            ? salesOrder.DeliveryTypeName
            : "-",
        },
        {
          title: "Term of Payment",
          separator: ":",
          value: salesOrder.TermOfPayment,
        },
        {
          title: "Due Date",
          separator: ":",
          value: this.dateFormat(salesOrder.DueDate),
        },
        { title: "Tax Status/Currency", separator: ":", value: "" },
        { title: "Sales Code/Salesman", separator: ":", value: "" },
      ];

      this.bodyData = invoiceLine.map((data) => {
        return {
          name: data.ItemName,
          quantity: data.Qty,
          uom: data.Unit,
          priceValue: this.priceFormat(data.Price),
          grossValue: this.priceFormat(data.Subtotal),
        };
      });

      const height = 1405.44;
      const width = 1082.88;
      let doc = new jsPDF({
        unit: "px",
        format: [width, height],
      });
      const header = document.getElementById("header");
      const line = document.getElementById("line");
      // const doc = new jsPDF("p", "px", "a4");
      // // const pageHeight = doc.internal.pageSize.getHeight();
      // const pageWidth = doc.internal.pageSize.getWidth();
      // // const headerHeight = 30;
      // // const footerHeight = 30;
      // await doc.html(header, {
      //   // autoPaging: "text", // Automatically handle paging
      //   callback: (doc) => doc,
      //   windowWidth: width,
      //   width: doc.internal.pageSize.getWidth(),
      //   // margin: [30, 30, 30, 30],
      // });
      console.log(doc.getCurrentPageInfo());
      doc = await this.generateLine(doc, line, width);
      const pages = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pages; i++) {
        doc.setPage(i);
        console.log("cur page ", i);
        console.log(doc.getCurrentPageInfo(), "info");
        doc.setFont("courier", "bold");
        doc.setFontSize(11);
        doc.text(
          10,
          doc.internal.pageSize.getHeight() - 10,
          `Page ${i} of ${pages}`
        );

        // doc = await this.generateHeader(doc, "<p>hello</p>", width);
        this.generateInvoiceInformation(
          doc,
          invoiceData,
          salesOrder,
          10,
          width
        );
      }

      const url = doc.output("dataurlstring");
      const iframe = document.getElementById("pdf-preview");
      iframe.src = url;
      this.$vs.loading.close();
      this.handleShowPopUp();
    } catch (e) {
      this.$vs.notify({
        title: "Error",
        text: e.message,
        color: "danger",
        position: "top-right",
      });
    }
  },
  data() {
    return {
      headerData: [],
      bodyData: [],
      totalData: [
        { title: "GROSS TOTAL", value: "4.382.478" },
        { title: "DISCOUNT", value: "100.081-" },
        { title: "SUBTOTAL", value: "4.282.397" },
        { title: "DP", value: "0" },
        { title: "DPP", value: "4.282.397" },
        { title: "PPN 11%", value: "471.063" },
        { title: "TOTAL", value: "4.753.460" },
      ],
      showPdf: false,
    };
  },
  methods: {
    generateKey(i) {
      return (
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15)
      );
    },
    determineLineClass(i) {
      if (i === 0) {
        return "pt-3";
      } else if (i === this.bodyData.length - 1) {
        return "pb-3";
      }
    },
    determineTotalClass(i) {
      if (i === 0) {
        return "pt-3";
      } else if (i === this.totalData.length - 1) {
        return "pb-3";
      }
    },
    handleClosePopUp() {
      this.showPdf = false;
    },
    handleShowPopUp() {
      this.showPdf = true;
    },
    getInvoiceData(invoice_id) {
      return this.$http.get("api/v1/invoice/read", {
        params: {
          invoice_id,
        },
      });
    },
    generateHeader(doc, element, width) {
      return new Promise((resolve) => {
        doc.html(element, {
          callback: function (doc) {
            resolve(doc);
          },
          autoPaging: "text", // Automatically handle paging
          windowWidth: width,
          width: doc.internal.pageSize.getWidth(), // top, left, bottom, right
          // margin: [1405.44 + 200, 0, 0, 0],
        });
      });
    },
    generateLine(doc, element, width) {
      return new Promise((resolve) => {
        doc.html(element, {
          callback: function (doc) {
            resolve(doc);
          },
          autoPaging: "text", // Automatically handle paging
          windowWidth: width,
          width: doc.internal.pageSize.getWidth(),
          margin: [300, 30, 0, 0],
        });
      });
    },
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    setPos(pageWidth, pos, offset) {
      if (pos >= pageWidth) {
        return pageWidth;
      } else {
        if (pos <= 0) {
          return offset;
        } else {
          return (pos += offset);
        }
      }
    },
    generateInvoiceInformation(pdf, invoice, salesOrder, cursorY, pageWidth) {
      const header = [
        { header: "", dataKey: "title" },
        { header: "", dataKey: "delimiter" },
        { header: "", dataKey: "value" },
      ];
      const body = [
        { title: "Billing No.", delimiter: ":", value: invoice.Code },
        {
          title: "Billing Date",
          delimiter: ":",
          value: moment(invoice.Date).format("DD.MM.YYYY"),
        },
        { title: "Order No.", delimiter: ":", value: salesOrder.Code },
        {
          title: "Order Date",
          delimiter: ":",
          value: moment(salesOrder.Date).format("DD.MM.YYYY"),
        },
        { title: "Customer No.", delimiter: ":", value: invoice.ClientCode },
        { title: "Sales Office", delimiter: ":", value: "" },
        {
          title: "Condition Delivery",
          delimiter: ":",
          value: salesOrder.DeliveryTypeName
            ? salesOrder.DeliveryTypeName
            : "-",
        },
        {
          title: "Term of Payment",
          delimiter: ":",
          value: invoice.PaymentTermName
            ? invoice.PaymentTermName
            : "Due within 30 days",
        },
        {
          title: "Due Date",
          delimiter: ":",
          value: moment(invoice.DueDate).format("DD.MM.YYYY"),
        },
        {
          title: "Tax Status/Currency",
          delimiter: ":",
          value: "PKP / IDR",
        },
        {
          title: "Sales Code/Salesman",
          delimiter: ":",
          value: `""`,
        },
      ];
      autoTable(pdf, {
        startY: cursorY - 0.4,
        columns: header,
        body: body,
        showHead: "never",
        styles: {
          lineWidth: 0,
          fontSize: 13,
          font: "courier",
          valign: "middle",
        },
        columnStyles: {
          title: { cellWidth: 140, cellPadding: 0 },
          delimiter: { cellWidth: 50, cellPadding: 0 },
          value: { cellWidth: 140, cellPadding: 0 },
        },
        theme: "plain",
        margin: { left: pageWidth / 2, right: 200 },
        didParseCell(data) {
          const columnIndex = data.column.index;
          if (columnIndex === 2) {
            data.cell.styles.halign = "left";
          }
        },
      });

      return pdf.lastAutoTable.finalY;
    },
  },
};
</script>

<style scoped>
.grid-display {
  display: grid;
  grid-template-columns: 1fr 0.05fr 1fr;
}
body {
  background: #ffffff;
}

.print-header {
  page-break-after: always;
}

.print-footer {
  /* display: table-footer-group; */
  /* page-break-after: always; */
}
@page {
  size: auto;
  margin: 0.5cm;
}
</style>
