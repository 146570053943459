var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col h-full py-1 px-6 gap-10 bg-white",attrs:{"id":"print-div"}},[_c('header',{staticClass:"flex flex-row justify-between p-6",attrs:{"id":"header"}},[_vm._m(0),_c('div',{staticClass:"flex flex-col w-1/2 pl-20"},[_c('h4',{staticClass:"text-center font-bold text-black"},[_vm._v("Faktur")]),_c('h4',{staticClass:"text-center font-bold text-black"},[_vm._v("(Billing)")]),_c('hr',{staticClass:"border-dashed border-0 border-t border-black mt-2 mb-2"}),_vm._l((_vm.headerData),function(data,indextr){return _c('div',{key:indextr,staticClass:"grid-display"},[_c('div',{staticClass:"text-left"},[_vm._v(_vm._s(data.title))]),_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(data.separator))]),_c('div',{staticClass:"text-left"},[_vm._v(_vm._s(data.value))])])})],2)]),_c('div',{staticClass:"flex flex-col px-6 py-10",attrs:{"id":"line"}},[_c('table',[_vm._m(1),_c('tbody',[_vm._l((_vm.bodyData),function(data,i){return _c('tr',{key:_vm.generateKey(i),class:i === _vm.bodyData.length - 1
              ? 'border-dashed border-0 border-b border-black'
              : ''},[_c('td',{class:_vm.determineLineClass(i)},[_vm._v(_vm._s(data.name))]),_c('td',{class:`${_vm.determineLineClass(i)} text-right pr-6`},[_vm._v(" "+_vm._s(data.quantity)+" ")]),_c('td',{class:_vm.determineLineClass(i)},[_vm._v(_vm._s(data.uom))]),_c('td',{class:_vm.determineLineClass(i)},[_vm._v(" "+_vm._s(`${data.priceValue} / 1 ${data.uom}`)+" ")]),_c('td',{class:`${_vm.determineLineClass(i)} text-right`},[_vm._v(" "+_vm._s(_vm.priceFormat(data.grossValue))+" ")])])}),_vm._l((_vm.totalData),function(data,i){return _c('tr',{key:i,class:i === _vm.totalData.length - 1
              ? 'border-dashed border-0 border-b border-black'
              : ''},[_c('td'),_c('td'),_c('td',{class:`font-bold text-black ${_vm.determineTotalClass(i)}`},[_vm._v(" "+_vm._s(data.title)+" ")]),_c('td'),_c('td',{class:`font-bold text-black text-right ${_vm.determineTotalClass(
              i
            )}`},[_vm._v(" "+_vm._s(data.value)+" ")])])})],2)]),_c('div',{staticClass:"flex font-bold text-black gap-1 mt-2"},[_c('span',[_vm._v(_vm._s("SAY"))]),_c('span',[_vm._v(":")]),_c('span',{attrs:{"id":"dummy2"}},[_vm._v(_vm._s("EMPAT JUTA TUJUH RATUS LIMA PULUH TIGA RIBU EMPAT RATUS ENAM PULUH RUPIAH"))])]),_c('hr',{staticClass:"border-dashed border-0 border-t border-black mt-2 mb-2"}),_c('span',{staticClass:"font-bold text-black"},[_vm._v("Sales Order : "+_vm._s("7840335562"))]),_c('span',{staticClass:"font-bold text-black"},[_vm._v("Customer PO. No. : "+_vm._s("ZS240320-002011/20032024"))]),_c('span',{staticClass:"font-bold text-black"},[_vm._v("Message : "+_vm._s(""))]),_c('hr',{staticClass:"border-dashed border-0 border-t border-black mt-2 mb-2"}),_vm._m(2)]),_c('vs-popup',{attrs:{"fullscreen":"","title":"fullscreen","active":_vm.showPdf,"button-close-hidden":false},on:{"update:active":function($event){_vm.showPdf=$event}}},[_c('div',{staticClass:"flex flex-col gap-6 h-full"},[_c('iframe',{staticClass:"w-full",staticStyle:{"min-height":"80vh"},attrs:{"id":"pdf-preview"}}),_c('div',{staticClass:"flex gap-3 w-full justify-end"},[_c('vs-button',{attrs:{"color":"danger"},on:{"click":_vm.handleClosePopUp}},[_vm._v("Cancel")])],1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col font-bold text-black gap-5 w-1/2 pr-20"},[_c('p',[_vm._v(" PT. SINARMAS DISTRIBUSI NUSANTARA "),_c('br'),_vm._v("Cab. Jakarta Selatan"),_c('br'),_vm._v(" Pergudangan Malatex Jl. Raya Bogor KM.30 Kel. Tugu, Kec. Cimanggis Depok ")]),_c('p',[_vm._v("021-87727150")]),_c('p',[_vm._v("NPWP SDN: 01.604.506.4-007.000")]),_c('p',[_vm._v(" Payer : "),_c('br'),_vm._v(" ANEKA BUANA FATMAWATI MM PT. HASIL KARSA MAKMUR JL. RS. FATMAWATI NO.15, RT.002/01 PONDOK LABU CILANDAK ")]),_c('p',[_vm._v("NPWP Customer : 01.372.280.6-062.000")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"border-dashed border-0 border-t border-b border-black text-left font-bold text-black"},[_c('td',{staticClass:"w-2/5 py-2"},[_vm._v("Product")]),_c('td',{staticClass:"w-1/5 py-2 text-center"},[_vm._v("Quantity")]),_c('td',{staticClass:"py-2"},[_vm._v("UoM")]),_c('td',{staticClass:"py-2"},[_vm._v("Price Value")]),_c('td',{staticClass:"py-2"},[_vm._v("Gross Value")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col gap-6 mt-6"},[_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"font-bold text-black"},[_vm._v("Notes:")]),_c('span',{attrs:{"id":"dummy"}},[_vm._v(" This is a computer generated document and no signature is required")])]),_c('span',{staticClass:"font-bold text-black"},[_vm._v("Bank Account : ")])])
}]

export { render, staticRenderFns }